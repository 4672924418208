
export const ENV_MODE = {
  DEV: "dev",
  STAGING: "staging",
  PRODUCTION: "production",
};

export const MODAL_ID = {
  ADMIN: "admin",
  USER: "user",
  REMOVE: "remove",
  UPDATE: "update",
  CONFIRM: "confirm",
  CONFIRM_DELETE: "confirm_delete",
  CHANGE_PASSWORD: "change_password",
  CREATE_TENANT: "create_tenant",
  UPDATE_TENANT: "update_tenant",
  OVERVIEW_MANAGEMENT: "overview_management",
  CREATE_ITEM: "create_item",
  CREATE_FORM_SETTING: "create_form_setting",
  SPECIFYING_FORM_SETTING: "specifying_form_setting",
  CREATE_TEAM: "create_team",
  UPDATE_TEAM: "update_team",
  UPDATE_TEAM_ADD_USER: "update_team_add_user",
  ADD_USER_CONFIRM: "update_team_add_user",
  CREATE_TEMPLATE_SORTING: "create_template_sorting",
  UPDATE_TEMPLATE_SORTING: "update_template_sorting",
  UPDATE_TEMPLATE_SORTING_ADD_FORM: "update_template_sorting_add_form",
};

export const MODAL_SIZE = {
  SMALL: "small",
};

export const SYSTEM_PATH = {
  HOME: "/",
  ADMIN: "/admin",
  DOCUMENT: "/documents",
  EDIT_DOCUMENT: "/documents/edit/:id",
  EDIT: "/documents/edit/",
  UPLOAD: "/upload",
  DOCUMENT_DOWNLOAD: "/documentsdownload",
  FORM_DEFINITION: "/definitions",
  FORM_GROUP: "/definitions/formgroup",
  TENANT: "/tenant",
  GENERAL_SETTING: "/generalsetting",
  INDIVIDUAL_SETTING: "/individualsetting",
  MODEL_MNG: "/models",
  FORM_SETTING: "/formsetting",
  TEAM: "/teams",
  EDIT_TEAM: "/teams/edit/:id",
  POST_PROCESSING: "/postprocessing",
  TEMPLATE_SORTING: "/templatesorting",
  EDIT_TEMPLATE_SORTING: "/templatesorting/edit/:id",
  REGIST_TEMPLATES: "/templatesorting/templates/:id",
  ACTION_LOG: "/actionlogs",
}


export const MODE = {
  ADD: "add",
  EDIT: "edit"
}

export const TABLE_ROW_WIDTH = {
  SMALL: "5%",
  NORMAL: "10%",
  LAGER: "20%",
  LARG: "25%",
  NORMAL_15_PERCENT: "15%",
  NORMAL_30_PERCENT: "30%",
  LARGEST: "60%",
};

export const NO_DATE = "-";

export const EMPTY_FORMAT_CELL = "-"
export const DATE_PLACHOLDER_FORMAT = "        -    -  "
export const DATE_FORMAT = {
  full: "HH:mm MMM DD, YYYY",
  backend: "YYYY-MM-DDTHH:mm:ss",
  frontend: "YYYY/MM/DD",
  frontend_minus: "YYYY-MM-DD",
  frontend_minus_4_d_p: "yyyy-MM-dd",
  analysis_range: "dddd, MMMM D, YYYY / hh:mm a",
  hour: "HH:mm:00",
  time_stamp: "x",
  full_time: "YYYY-MM-DD HH:mm:ss"
};

export const PASSWORD_HIDDEN = "********";

export const ACCESSOR_DOWNLOAD = {
  ID: "id",
  INDEX: "idx",
  FILE_NAME: "inputFileName",
  PAGE_NO: "pageNo",
  FORM_GROUP: "formGroupId",
  CONFIG_NAME: "configName",
  CREATED_AT: "createdAt",
  UPDATED_AT: "updatedAt",
  STATUS: "status",
  ACTION: "action",
}

export const ACCESSOR_DOCUMENT_DOWNLOAD = {
  INDEX: "idx",
  FILE_NAME: "fileNameOriginal",
  FORM_GROUP: "formGroupName",
  CONFIG_FILE: "configFileName",
  CREATED_AT: "createdAt",
  TEAMS_NAME: "teamsId",
  USER_ID: "userId",
  STATUS: "downloadStatus",
}

export const ACCESSOR_LOG = {
  INDEX: "idx",
  ACTION: "action",
  ACTION_DETAIL: "actionDetail",
  USER_ID: "userId",
  EXECUTED_AT: "executedAt",
}

export const MODAL_TYPE = {
  SMALL: "small"
}

export const DEFAULT_PAGE_SIZE = 100;
export const PAGE_SIZE_OPTIONS = [
  10, 50, 100, 500, 1000
];

export const DATE_SEPARATE = "~";

export const ACCESSOR_SEARCH_BOX = {
  FILE_NAME_INPUT: "inputFileName",
  FILE_NAME_CONFIG: "configFileName",
  FILE_CODE_CONFIG: "configFileCode",
  FORM_GROUP: "formGroupId",
  FROM_TIME: "fromTime",
  TO_TIME: "toTime",
  STATUS_PROCESSING: "processing",
  STATUS_ERROR: "error",
  STATUS_FINISHED: "finished",
  STATUS_UPDATED: "updated",
  STATUS_CONFIRMED: "confirmed",
  STATUS_DOWNLOADED: "downloaded",
  TEAM_ID: "teamId",
}

export const DOWNLOAD_ACCESSOR_SEARCH_BOX = {
  FILE_NAME_INPUT: "filenameOriginal",
  CONFIG_FILE_ID: "configFileId",
  FORM_GROUP_ID: "formGroupId",
  FROM_TIME: "fromTime",
  TO_TIME: "toTime",
  STATUS_NOT_DOWNLOADED: "notDownloaded",
  STATUS_DOWNLOADED: "downloaded",
  TEAM_ID: "teamId",
  USER_ID: "userId",
}

export const LOG_ACCESSOR_SEARCH_BOX = {
  FROM_TIME: "fromTime",
  TO_TIME: "toTime",
  ACTION_LOGIN: "login",
  ACTION_LOGOUT: "logout",
  ACTION_RECORD_UPLOAD: "documentUpload",
  ACTION_CSV_DOWNLOAD: "csvDownload",
  ACTION_FORM_DEFINITION_UPLOAD: "formUpload",
  ACTION_CORRECT_READING_RESULT: "editDocument",
  ACTION_DELETE_DOCUMENT: "documentDelete",
  ACTION_DEBUG_DOWNLOAD: "debugDownload",
  ACTION_RECORD_DOWNLOAD: "documentDownload",
  USER_ID: "userId",
}

export const DOCUMENT_STATUS = {
  PROCESSING: "0",
  ERROR: "1",
  COMPLETED: "2",
  UPDATED: "3",
  DOWNLOADED: "4",
  CONFIRMED: "5",
};

export const DOCUMENT_DOWNLOAD_STATUS = {
  NOT_DOWNLOAD: "0",
  DOWNLOADED: "1",
};

export const TEMPLATE_SORTING_STATUS = {
  NOT_REGISTED: 0,
  REGISTED: 1,
};

export const TEMPLATE_SORTING_CLASSIFICATION_TYPE = {
  IMAGE: "IMAGE_FEATURE",
  KEYWORD: "KEYWORD",
};

export const PROFILE_ROLES_PREFIX = "profile.role_";

export const AUTO_COMPLETE_STRING = "new-password";
export const AUTO_COMPLETE_ON = "on";
export const MAX_ZOOM_LEVEL = 10;
export const MIN_ZOOM_LEVEL = 0.5;
export const ZOOM_STEP = 0.5;
export const DEFAULT_AUTO_SCALE = 1.5;

export const LANGUAGE_CODE = {
  JP: 0,
  EN: 1
};
export const LANGUAGE_CODE_DEFAULT = LANGUAGE_CODE.JP;

export const RENEWAL_SPAN_CODE = {
  MONTH: 0,
  YEAR: 1
};
export const RENEWAL_SPAN_CODE_DEFAULT = RENEWAL_SPAN_CODE.MONTH;

// There are alot of table in system. Example: Document, Form difinition, User, Tenant.
export const TABLE_CODE = {
  DOCUMENT: 0,
  LOG: 1,
  DOCUMENT_DOWNLOAD: 2,
  PREBUILT_SETTING: 3,
  CLAUDE35_SETTING: 4,
}

// CSV
export const DOWNLOAD_FILE_FORMAT = {
  CSV: 0,
  CSV_AIREAD: 1
};
export const DOWNLOAD_FILE_FORMAT_DEFAULT = DOWNLOAD_FILE_FORMAT.CSV;
export const DOWNLOAD_FILE_CHAR_ENCODING = {
  SJIS: 0,
  UTF_8_WITHOUT_BOM: 1,
  UTF_8_WITH_BOM: 2,
};
export const DOWNLOAD_FILE_CHAR_ENCODING_DEFAULT = DOWNLOAD_FILE_CHAR_ENCODING.SJIS;
export const DOWNLOAD_FILE_RECOGNIZE_NEWLINE = {
  NEWLINE_STRING: 0,
  NEWLINE_CODE: 1
};
export const DOWNLOAD_FILE_RECOGNIZE_NEWLINE_DEFAULT = DOWNLOAD_FILE_RECOGNIZE_NEWLINE.NEWLINE_STRING;
export const READ_COMPLETE_EMAIL_NOTIFICATION = {
  ON: 0,
  OFF: 1,
}
export const READ_COMPLETE_EMAIL_NOTIFICATION_DEFAULT = READ_COMPLETE_EMAIL_NOTIFICATION.ON;
export const READ_COMPLETE_EMAIL_NOTIFICATION_DEFAULT_LGWAN = READ_COMPLETE_EMAIL_NOTIFICATION.OFF;

export const FORM_GROUP_SELECT_TEAMS_MODE = {
  ALL_TEAMS: 0,
  INDIVIDUAL_TEAMS: 1,
};

export const MAX_FILE_UPLOAD = 100;

export const LOG_ACTION = {
  RECORD_UPLOAD: 0,
  CSV_DOWNLOAD: 1,
  FORM_DEFINITION_UPLOAD: 2,
  CORRECT_READING_RESULT: 3,
  LOGIN: 4,
  LOGOUT: 5,
  DELETE_DOCUMENT: 6,
  DEBUG_DOWNLOAD: 7,
  RECORD_DOWNLOAD: 8,
};

export const DOCUMENT_DOWNLOAD_SETTING = {
  GLOBAL: 0,
  INDIVIDUAL: 1,
};

export const FORMGROUP_PREBUILT = -1;

export const CONFIG_FILE_EDITION = {
  MANUAL: "Manual",
  ITEM: "Item",
  PREBUILT: "Prebuilt",
  RULE: "Rule",
};

export const FORM_SETTING_EDITION = {
  ITEM: "Item",
  PREBUILT: "Prebuilt",
  CLAUDE_3_5: "Claude3.5",
};

export const PREBUILT_MODELS = {
  INVOICE: "__prebuilt-invoice",
  RECEIPT: "__prebuilt-receipt",
  CLAUDE_3_5: "__prebuilt-claude_3_5",
}

export const ACCESSOR_FORMSETTING_PREBUILT = {
  ID: "id",
  DEFAULT: "defaultItemName",
  TYPE: "type",
  DESCRIPTION: "description",
  OUTPUT: "output",
  CONVERTED: "convertedItemName",
}

export const PREBUILT_TABLE_ORDER = {
  "": 0,
  "Items": 1,
  "TaxDetails": 2,
}

export const PREBUILT_COLUMN_ORDER_INVOICE = {
  "": {
    "CustomerName": 0,
    "CustomerId": 1,
    "PurchaseOrder": 2,
    "InvoiceId": 3,
    "InvoiceDate": 4,
    "DueDate": 5,
    "VendorName": 6,
    "VendorTaxId": 7,
    "VendorAddress": 8,
    "VendorAddressRecipient": 9,
    "CustomerAddress": 10,
    "CustomerTaxId": 11,
    "CustomerAddressRecipient": 12,
    "BillingAddress": 13,
    "BillingAddressRecipient": 14,
    "ShippingAddress": 15,
    "ShippingAddressRecipient": 16,
    "PaymentTerm": 17,
    "SubTotal": 18,
    "TotalTax": 19,
    "InvoiceTotal": 20,
    "AmountDue": 21,
    "ServiceAddress": 22,
    "ServiceAddressRecipient": 23,
    "RemittanceAddress": 24,
    "RemittanceAddressRecipient": 25,
    "ServiceStartDate": 26,
    "ServiceEndDate": 27,
    "PreviousUnpaidBalance": 28,
    "CurrencyCode": 29,
    "PaymentDetails": 30,
    "TotalDiscount": 31,
  },
  "Items": {
    "Items": 0,
    "ProductCode": 1,
    "Date": 2,
    "Description": 3,
    "Quantity": 4,
    "Unit": 5,
    "UnitPrice": 6,
    "Amount": 7,
    "Tax": 8,
    "TaxRate": 9,
  },
}

export const PREBUILT_COLUMN_ORDER_RECEIPT = {
  "": {
    "MerchantName": 0,
    "MerchantPhoneNumber": 1,
    "MerchantAddress": 2,
    "TransactionDate": 3,
    "TransactionTime": 4,
    "Subtotal": 5,
    "Total": 6,
    "TotalTax": 7,
    "Tip": 8,
    "ArrivalDate": 9,
    "DepartureDate": 10,
    "Currency": 11,
    "MerchantAliases": 12,
  },
  "Items": {
    "ProductCode": 0,
    "Date": 1,
    "Description": 2,
    "Quantity": 3,
    "QuantityUnit": 4,
    "Price": 5,
    "TotalPrice": 6,
    "Category": 7,
  },
  "TaxDetails": {
    "Amount": 0,
  },
}

export const ACCESSOR_FORMSETTING_CLAUDE35 = {
  INDEX: "idx",
  KEY: "columnKey",
  PATTERN: "columnPattern",
  ACTION: "action",
}

export const USER_STATUS = {
  ENABLE: 0,
  DISABLE: 1,
  DELETE: 2,
}
