export default {
  translation: {
    //api message
    "api.response.worng_format": "The response body is not correct format.",
    "api.response.no_message": "No error message reponse.",
    "api.response.server_error": "There was a problem with your request. Please try again later",
    "api.response.no_network": "There was a problem with your request. Please try again later",

    //static page
    "staticpage.notfound.title": "Oops! Page not found.",
    "staticpage.notfound.description": "We could not find the page you were looking for. Meanwhile, you may ",
    "staticpage.notfound.return": "return to home",
    //login screens
    // 'login.brand_name': "AIRead on Cloud",
    // "login.title": "Sign in to start your session",
    // "login.userId.placeHolder": "User ID",
    // "login.password.placeHolder": "Password",
    // "login.fields.required": "Please enter your user ID / password",
    // "login.signin": "Login",
    // "login.worngcridential": "Invalid user id / password",
    'login.brand_name': "AIRead on Cloud",
    "login.title": "AIRead ログイン (Login)",
    "login.userId.placeHolder": "メールアドレス (mail address)",
    "login.password.placeHolder": "パスワード (password)",
    "login.fields.required": "メールアドレス または パスワード が入力されていません (Please enter your mail address / password)",
    "login.signin": "Login",
    "login.worngcridential": "メールアドレス または パスワード が異なります \r\n入力内容をご確認ください (Invalid mail address / password)",

    // common
    "table_no_data": "No data",
    "table_rearrange_order": "Toggle SortBy",
    "common.version": "Version {{version}}",
    "common.version_suffix_dev": " - Development",
    "common.version_suffix_stagging": " - Staging",
    "common.create": "Create",
    "common.save": "Save",
    "common.update": "Update",
    "common.remove": "Remove",
    "common.update_confirm": "Update confirmation",
    "common.remove_confirm": "Remove confirmation",
    "common.created_date": "Created date",
    "common.updated_date": "Updated date",
    "common.no": "No.",
    "common.ok": "OK",
    "common.cancel": "Cancel",

    //layout -header
    "header.sign_out": "Logout",
    //user module text
    "user.confirmation": "Confirmation",
    "user.re-create-user-messsage": "UserID has been deleted. Do you want re-create this UserID?",
    "user.create": "Create",
    "user.cancel": "Cancel",

    // For validate
    "validate.email_invalid": "Email is invalid",
    "validate.password_condition": "Password length is at least 8 characters, contains at least one digit, one upper case alpha char and one lower case alpha char",
    "validate.password_mismatch": "Password and confirm password do not match",
    "validate.field_is_empty": "This field can not be empty",
    "validate.field_is_require": "This item is a required item",
    "validate.user_id_invalid": "User ID is invalid",
    "validate.admin_id_invalid": "Admin ID is invalid",
    "validate.contract_number_validate": "Integer greater than 100",
    "validate.account_number_validate": "Integer greater than 1",
    "validate.date_range_invalid": "Start date must be before End date",
    "validate.threshold": "The threshold must be between 0 and 99",
    "validate.threshold_invalid": "Left threshold must be greater than right threshold",
    "validate.more_or_equal": "Value must be greater than or equal to 0",
    "validate.validate.classificationType": "Incorrect classification type",
    "validate.validate.maxFeatures": "Integer greater than 1",
    "validate.validate.matchCtTh": "Integer greater than 0",
    "validate.validate.sizeDiffTh": "Integer greater than -1",
    "validate.validate.keywordRect": "Incorrect reading range",
    "validate.validate.ipaddress": "Incorrect IP address format",
    "validate.download_folder_name": "Incorrect download folder name format",
    "validate.download_file_name": "Incorrect download file name format",

    // For form definition
    "definition.change_information": "Do you want to change information?",
    "definition.remove_confirm": "Remove confirmation",
    "definition.update_confirm": "Update confirmation",
    "definition.required": "is required",
    "definition.update": "Update",
    "definition.save": "Save",
    "definition.remove_user": "Do you want to remove this form definition?",
    "definition.updated_date": "Updated date",
    "definition.created_date": "Created date",
    "definition.definition_management": "Form Definition Management",
    "definition.definition_update": "Form Definition Management",
    "definition.cancel": "Cancel",
    "definition.user_id": "User ID",
    "definition.remove": "Remove",
    "definition.brandname": "AIRead on Cloud",
    "definition.action": "Remove",
    "definition.no": "No.",
    "definition.config_name": "Config name",
    "definition.form_id": "Form ID",
    "definition.form_group_id": "Form Group ID",
    "definition.form_group_name": "Group name",
    "definition.edition": "Edition",
    "definition.edition.manual": "Coordinate",
    "definition.edition.item": "Rule v1",
    "definition.edition.rule": "Rule v2",
    "definition.edition.prebuilt": "Prebuilt",
    "definition.created_by": "Created by",
    "definition.form_group_management": "Form Group Management",
    "definition.download_file_Header_item": "Edit Download File Output Items",

    // For form group
    "definition.form_group.no": "No.",
    "definition.form_group.edition": "Edition",
    "definition.form_group.edition.manual": "Coordinate / Rule v2",
    "definition.form_group.edition.item": "Rule v1",
    "definition.form_group.edition.prebuilt": "Prebuilt",
    "definition.form_group.form_group_name": "Group Name",
    "definition.form_group.form_setting_name": "Form setting name",
    "definition.form_group.teams_name": "Teams to use",
    "definition.form_group.teams_all": "All Teams",
    "definition.form_group.created_date": "Created date",
    "definition.form_group.updated_date": "Updated date",
    "definition.form_group.download_file_Header_item": "Edit Download File Output Items",
    "definition.form_group.download_config_files_list": "Download Form Definition",
    "definition.form_group.remove": "Remove",
    "definition.form_group.back": "Back",
    "definition.form_group.form_group_management": "Group Management",
    "definition.form_group.remove_confirm_msg": "Are you sure you want to delete this group?\r\nThe form definitions of the deleted group will be deleted at the same time.",
    "definition.form_group.setting.page_title": "Group Settings",
    "definition.form_group.setting.confirm_save": "Do you want to change information?",
    "definition.form_group.setting.save": "Save",
    "definition.form_group.setting.cancel": "Cancel",
    "definition.form_group.setting.teams.all": "All teams",
    "definition.form_group.setting.teams.individual": "Designate individual teams",
    "definition.form_group.setting.teams.no": "No.",
    "definition.form_group.setting.teams.name": "Team Name",
    "definition.form_group.setting.download": "Report Download",
    "definition.form_group.setting.download.global": "Follow tenant settings",
    "definition.form_group.setting.download.individual": "Individually configured",
    "definition.form_group.setting.download.foldername": "Folder Name",
    "definition.form_group.setting.download.filename": "File Name",

    // For download file header item
    "definition.download_file_header_item.page_title": "Download file output items",
    "definition.download_file_header_item.header.title": "Header items",
    "definition.download_file_header_item.detail.title": "Details items",
    "definition.download_file_header_item.detail.tooltip": "Valid only when the download file format is \"CSV\"",
    "definition.download_file_header_item.regist": "Registration",
    "definition.download_file_header_item.init": "Restore default settings",
    "definition.download_file_header_item.items": "Item",
    "definition.download_file_header_item.output_items": "Output item",
    "definition.download_file_header_item.item_up": "Up",
    "definition.download_file_header_item.item_down": "Down",
    "definition.download_file_header_item.save_confirm": "Register download file output items. Are you sure?",
    "definition.download_file_header_item.cancel": "Cancel",
    "definition.download_file_header_item.Image": "Upload file name[Image]",
    "definition.download_file_header_item.processDate": "Completion date and time[processDate]",
    "definition.download_file_header_item.result": "Processing result[result]",
    "definition.download_file_header_item.document_id": "Document ID[document_id]",
    "definition.download_file_header_item.form_id": "Form ID[form_id]",
    "definition.download_file_header_item.page_no": "Page number[page_no]",
    "definition.download_file_header_item.setting_type": "Setting category",
    "definition.download_file_header_item.take_over_group_setting": "Take over group settings",
    "definition.download_file_header_item.individually_configured": "Setup per form definition",

    // For admin
    "admin.create_user": "Do you create new user?",
    "admin.create_tenant": "Create new tenant",
    "admin.change_information": "Do you want to change information?",
    "admin.remove_confirm": "Remove confirmation",
    "admin.update_confirm": "Update confirmation",
    "admin.change_status": "Do you want to change status of user?",
    "admin.account_page": "Account page",
    "admin.editing_password": "Editing Password",
    "admin.required": "is required",
    "admin.email": "E-mail",
    "admin.password_confirm": "Password confirm",
    "admin.contractor_name": "Admin name",
    "admin.contractor_setting": "Contractor setting",
    "admin.update": "Update",
    "admin.save": "Save",
    "admin.password": "Password",
    "admin.remove_user": "Do you want to remove this user?",
    "admin.unlock_user": "Do you want to unlock this user account?",
    "admin.last_logon_date": "Logon time",
    "admin.updated_date": "Updated time",
    "admin.created_date": "Created time",
    "admin.account_lock": "Account lock",
    "admin.unlock": "Unlock",
    "admin.unlock_btn": "Unlock",
    "admin.user_name": "User name",
    "admin.user_level": "User Level",
    "admin.user_management": "User Management",
    "admin.admin": "Admin",
    "admin.contractor": "Contractor",
    "admin.cancel": "Cancel",
    "admin.user_id": "User ID",
    "admin.status": "Valid / Invalid",
    "admin.status_valid": "Valid",
    "admin.status_invalid": "Invalid",
    "admin.remove": "Remove",
    "admin.brandname": "AIRead on Cloud",
    "admin.action": "Remove",
    "admin.no": "No.",

    // For setting user
    "setting_user.change_information": "Do you want to change information?",
    "setting_user.update_confirm": "Update confirmation",
    "setting_user.editing_password": "Editing Password",
    "setting_user.required": "is required",
    "setting_user.email": "E-mail",
    "setting_user.password_confirm": "Password confirm",
    "setting_user.create": "Create",
    "setting_user.confirmation": "Confirmation",
    "setting_user.save": "Save",
    "setting_user.password": "Password",
    "setting_user.user_name": "User name",
    "setting_user.cancel": "Cancel",
    "setting_user.user_id": "User ID",
    "setting_user.user_id_place_holder": "Please enter an email",
    "setting_user.status": "Status",
    "setting_user.language": "Language",
    "setting_user.language.english": "English",
    "setting_user.language.japan": "日本語",
    "setting_user.user_level": "User Level",
    "setting_user.disable_confirm": "Account deactivation confirmation",
    "setting_user.disable_information": "The number of contracted users has been exceeded. Do you want to deactivate your account?",
    "setting_user.disable_yes": "Yes",
    "setting_user.disable_no": "No",

    // For setting definition
    "setting_definition.change_information": "Do you want to change information?",
    "setting_definition.update_confirm": "Update confirmation",
    "setting_definition.required": "is required",
    "setting_definition.confirmation": "Confirmation",
    "setting_definition.save": "Save",
    "setting_definition.config_name": "Config Name",
    "setting_definition.cancel": "Cancel",
    "setting_definition.definition_id": "ID",

    // For search box
    "search_box.account": "account",
    "search_box.sheets": "sheets",
    "search_box.sheet": "sheet",
    "search_box.accounts": "accounts",
    "search_box.create_user_btn": "Create new user",
    "search_box.change_setting": "Change setting",
    "search_box.num_contract_account": "Number of contract accounts",
    "search_box.number_of_contracts": "Number of contracts",
    "search_box.use_this_month": "Use this month",
    "search_box.file_name": "[File name]",
    "search_box.file_name_place_holder": "File name",
    "search_box.formGroup": "[Group]",
    "search_box.definition_file": "[Definition file]",
    "search_box.narrowing_conditions": "Narrowing Conditions",
    "search_box.registered_date": "[Registered date]",
    "search_box.to_date": "[To Date]",
    "search_box.status": "[Status]",
    "search_box.processing": "Processing",
    "search_box.read_error": "Error reading",
    "search_box.reading_completed": "Reading completed",
    "search_box.updated": "Updated",
    "search_box.downloaded": "Confirmed",
    "search_box.downloaded": "Downloaded",
    "search_box.search": "Search",
    "search_box.ok": "OK",
    "search_box.brand_name": "AIRead on Cloud",
    "search_box.from_time_greater_to_time": "To Date should be greater than From Date",
    "search_box.team_name": "[Team name]",

    // For profile
    "profile.sheet": "sheet",
    "profile.sheets": "sheets",
    "profile.company_information": "Contractor",
    "profile.user_level": "User level",
    "profile.number_of_contracts": "Number of contracts",
    "profile.use_this_month": "Use this month",
    "profile.monthly_usage": "Monthly usage",
    "profile.user_information": "User Information",
    "profile.user_id": "User ID",
    "profile.role_0": "Administrator User",
    "profile.role_1": "Normal User",
    "profile.role_2": "Arise Administrator",
    "profile.tenant_notification": "Notification",
    "profile.per_month": "/ month",
    "profile.per_year": "/ year",
    "profile.period_of_use": "Period of use",
    "profile.period_of_use_year": "",
    "profile.period_of_use_month": "",
    "profile.period_of_use_update": "Update",

    // For pagging
    "pagging.show": "Show",
    "pagging.next": "Next",
    "pagging.previous": "Previous",
    "pagging.show_record": "Showing {{fromIndex}} ～ {{toIndex}} record in total {{dataLength}} records",

    // For edit
    "edit.preview": "Preview",
    "edit.item_confidence": "Confidence",
    "edit.file_name": "File name",
    "edit.screen": "Correct reading result",
    "edit.item_name": "Item name",
    "edit.output_value": "Output value",
    "edit.output_value_place_holder": "Output value",
    "edit.verification": "Verify",
    "edit.cancel": "Cancel",
    "edit.save": "Save",
    "edit.cancel_confirm_title": "Confirmation",
    "edit.cancel_description": "Return without saving. Is it OK?",
    "edit.cancel_yes": "Yes",
    "edit.cancel_no": "No",
    "edit.save_confirm_title": "Confirmation",
    "edit.save_description": "Saved. Return to the list page?",
    "edit.save_yes": "Yes",
    "edit.save_no": "No",
    "edit.verify_at_least_one_item_to_save": "Please verify or edit at least one item to save.",
    "edit.update_confirm_title": "Confirmation",
    "edit.update_description": "The data has been updated. Please refresh the screen.",
    "edit.update_yes": "Yes",
    "edit.update_no": "No",
    "edit.info_file_name": "File name",
    "edit.info_definition_file": "Definition file",
    "edit.info_status": "Status",
    "edit.change_page_confirm_title": "Confirmation",
    "edit.change_page_description": "Next/Previous document without saving. Is it ok?",
    "edit.change_page_yes": "Yes",
    "edit.change_page_no": "No",
    "edit.remove_confirm_msg": "Do you want to remove this item?",
    "edit.add_new_title": "Add Item",
    "edit.required_msg": "Is a required field",
    //"edit.item_name": "Item name",
    "edit.add_one_line": "Add one line",
    "edit.add_one_line_above": "Add one line above",
    "edit.delete_line": "Delete line",
    "edit.confirm": "Confirm",
    "edit.unconfirm": "Unconfirm",
    "edit.unconfirm_before_edit": "Please unconfirm this document in order to edit.",
    "edit.table.prebuilt.header": "Header",
    "edit.table.prebuilt.items": "Items",
    "edit.table.prebuilt.tax_details": "TaxDetails",
    "edit.table.prebuilt.claude35.detail": "Detail",

    // For upload
    "upload.review": "Preview",
    "upload.filename": "Name",
    "upload.format": "Format",
    "upload.size": "Size",
    "upload.status": "Status",
    "upload.action": "Del",
    "upload.sort_definition_file": "Specify the definition file",
    "upload.key_sorting": "Use keyword sorting (RuleEditor definition only)",
    "upload.sort_definition_tooltip_1": "Please select a definition file with edition = Item to enable sort function",
    "upload.sort_definition_tooltip_2": "",
    "upload.sort_definition_title": "Sort Definition",
    "upload.no": "No.",
    "upload.cant_upload_filenum": "Can't upload more than 100 files ",
    "upload.btn_select_file": "File selection",
    "upload.confirmation": "Confirmation",
    "upload.confirmation_delete": "Remove Confirmation",
    "upload.confirm_desc": "Start the reading process. Is it OK?",
    "upload.confirm_delete_desc": "Do you want to remove this file?",
    "upload.cancel": "Cancel",
    "upload.close": "Close",
    "upload.cant_upload_warning": "Warning",
    "upload.execute": "Execute",
    "upload.remove": "Remove",
    "upload.execute_reading": "Execute reading",
    "upload.drag_drop_desc": "Drag & drop file here, or click to select file",
    "upload.file_name": "File name",
    "upload.report_upload": "Report upload",
    "upload.file_format": "File format",
    "upload.file_size": "File size",
    "upload.definition_file": "Definition file",
    "upload.file_erorrs": "Upload failed. Please try again later.",
    "upload.file_size_over": "File size should be within 100.0 MB. (PDF, JPG, TIFF, PNG)",
    "upload.file_size_over_input": "File size should be within 100.0 MB. (PDF, JPG, TIFF, PNG and up to 100 files).",
    "upload.file_type_not_allow": "The uploaded file is not in PDF format. Upload a PDF fileThe uploaded file is not in PDF format.",
    "upload.upload_file_to_execute_reading": "Please upload file before execute reading.",
    "upload.upload_definition_to_execute_reading": "Please select a definition file before execute reading.",
    "upload.template_sorting_filter": "Sort by Images / Keywords (Coordinate, Rule v2)",
    "upload.group_definition_filter": "Sort by Keywords (Rule v1)",
    "upload.no_group": "Order document",
    "upload.not_group_definition_filter": "Select Definition File",
    "upload.copy": "API Token copied to clipboard",
    "upload.prebuilt_definition": "Prebuilt model definition",
    "upload.prebuilt_invoice": "Invoice",
    "upload.prebuilt_receipt": "Receipt",
    "upload.claude_3_5": "Claude 3.5 (Evaluation edition)",

    // For download
    "download.no": "No.",
    "download.csv_download": "CSV download",
    "download.file_name": "File name",
    "download.definition_file": "Definition file",
    "download.registered_date": "Registered date",
    "download.status": "Status",
    "download.download": "Download CSV",
    "download.download_reading_results": "Download debug information",
    "download.pagenumber": "Page number",
    "download.formGroup": "Group",
    "download.update_date_and_time": "Updated date",
    "download.edit": "Edit",
    "download.ok": "OK",
    "download.select_to_download": "Please select the data to download.",
    'download.brand_name': "AIRead on Cloud",
    "download.status_0": "Processing",
    "download.status_1": "Error reading",
    "download.status_2": "Reading completed",
    "download.status_3": "Updated",
    "download.status_4": "Downloaded",
    "download.status_5": "Confirmed",
    "download.action": "Edit",
    "download.btn_update": "Update",
    "document.remove": "Remove",
    "document.remove_confirm": "Remove confirmation",
    "document.remove_document": "Remove the selected data. Is it OK.",
    "document.remove_yes": "Yes",
    "document.remove_no": "No",
    "download.select_to_remove": "Please select the data to remove.",
    "download.selected_item": "Selected item(s): ",
    "download.select_data_must_same_type": "To check all data, the type of them on this page must be same to each other.\r\nSpecifically with the case of [Item] they need to be belong to the same group.",
    "download.cant_download_confirm": "Confirmation",
    "download.cant_download_warning": "Warning",
    "download.cant_download_document": "Error reading record cannot be downloaded",
    "download.cant_download_close": "Close",
    "download.prebuilt": "Prebuilt-Model",
    "download.prebuilt_invoice": "Invoice",
    "download.prebuilt_receipt": "Receipt",
    "download.prebuilt_claude_3_5": "Claude 3.5",

    "search_box.all_definition_file": "[Please select]",

    "menu.user_management": "User Management",
    "menu.csv_download": "CSV Download",
    "menu.report_upload": "Report Upload",
    "menu.report_download": "Report Download",
    "menu.form_definition": "Form Definition",
    "menu.tenant_management": "Tenant Management",
    "menu.general_setting": "Environment Setting",
    "menu.individual_setting": "Individual Setting",
    "menu.model_management": "Individual Learning Model",
    "menu.form_setting": "Form Setting",
    "menu.team": "Team Management",
    "menu.post_processing": "Post Processing Setting",
    "menu.template_sorting": "Sorting Management",
    "menu.action_logs": "Logs",

    "change_password.submit": "Change",
    "change_password.title": "Change Password",
    "change_password.menu_button": "Change Password",
    "change_password.old_password": "Current Password",
    "change_password.new_password": "New Password",
    "change_password.confirm_password": "Confirm Password",

    "tenant.create_tenant_confirm": "新しいテナントを作成します。よろしいですか？",
    "tenant.page_title": "テナント管理 利用状況",
    "tenant.show_password": "パスワードを表示",
    "tenant.month_year": "利用年月",
    "tenant.year": "年",
    "tenant.month": "月",
    "tenant.create_tenant": "テナント作成",
    "tenant.checkbox_active": "利用期間中の契約者のみ表示する",
    "tenant.success_document": "利用枚数",
    "tenant.success_item": "(item)",
    "tenant.success_manual": "(manual)",
    "tenant.success_rule": "(rule)",
    "tenant.success_prebuilt": "(prebuilt)",
    "tenant.fail_document": "読取エラー",
    "tenant.start_date": "利用開始日",
    "tenant.end_date": "利用終了日",
    "tenant.total_table": "合計",
    "tenant.required": "は必須入力項目です",
    "tenant.email": "E-mail",
    "tenant.update": "更新",
    "tenant.id": "TenantID",
    "tenant.name": "契約者名",
    "tenant.name_place_holder": "正式な企業・組織名",
    "tenant.cancel": "キャンセル",
    "tenant.date_range": "利用期間（開始～終了）",
    "tenant.total": "契約枚数",
    "tenant.total_place_holder": "S(100), M(500), L(1000)",
    "tenant.renewal_span": "契約更新単位",
    "tenant.renewal_span_month": "月",
    "tenant.renewal_span_year": "年",
    "tenant.admin_id": "管理者ID",
    "tenant.admin_id_place_holder": "管理者のメールアドレス",
    "tenant.active_user": "契約アカウント数",
    "tenant.active_user_place_holder": "契約者数 最少は 1",
    "tenant.admin_name": "管理者名",
    "tenant.admin_name_place_holder": "管理者のユーザ名",
    "tenant.admin_password": "パスワード",
    "tenant.confirm_password": "パスワード再入力",
    "tenant.submit": "作成",
    "tenant.no": "No.",
    "tenant.language": "言語",
    "tenant.language.english": "English",
    "tenant.language.japan": "日本語",
    "tenant.update_submit": "変更",
    "tenant.update_tenant": "テナント内容変更",
    "tenant.update_tenant.news": "お知らせ",
    "tenant.overview_management": "全体管理",
    "tenant.overview_management.info_url": "お知らせ（ログイン）",
    "tenant.overview_management.info_url.place_holder": "URLを入力",
    "tenant.overview_management.news": "お知らせ（全体共通）",
    "tenant.overview_management.news.place_holder": "メッセージを入力",
    "tenant.update_tenant_confirm": "テナント内容変更。よろしいですか？",
    "tenant.team_name": "チーム名",

    "general_setting.threshold_title": "Confidence threshold for reading results",
    "general_setting.low": "Conf. Low",
    "general_setting.mid": "Conf. Mid",
    "general_setting.high": "Conf. High",
    "general_setting.save": "Save",
    "general_setting.api_token": "API Token",
    "general_setting.generate": "Generate",
    "general_setting.data_retention_period": "Data retention period",
    "general_setting.data_retention_period.tooltip": "Deletes data older than the specified number of days from the server. If it is 0, it is indefinite.",
    "general_setting.document_download": "Report Download",
    "general_setting.document_download_folder": "Folder Name",
    "general_setting.document_download_file": "File Name",
    "general_setting.document_download_description_before_link": "*See \"Preferences\" in ",
    "general_setting.document_download_description_link": "this manual",
    "general_setting.document_download_description_after_link": " for available tags",
    "general_setting.day": "day",
    "general_setting.restriction_ip_address": "Connection Restriction by IP Address",
    "general_setting.restriction_ip_address.edit": "Edit",
    "general_setting.restriction_ip_address.page_title": "Connection Restriction by IP Address",
    "general_setting.restriction_ip_address.ip": "IP Address",
    "general_setting.restriction_ip_address.comment": "Comment",
    "general_setting.restriction_ip_address.back": "Back",
    "general_setting.restriction_ip_address.create": "Add rule",
    "general_setting.restriction_ip_address.remove_confirm": "Remove confirmation",
    "general_setting.restriction_ip_address.remove_confirm_msg": "Do you want to remove this IP address?",
    "general_setting.restriction_ip_address.detail.page_title": "Connection Restriction by IP Address[Management]",
    "general_setting.restriction_ip_address.detail.ip.desc_1": "A range of subnet masks can be specified",
    "general_setting.restriction_ip_address.detail.ip.desc_2": "[Example of IP address specification]",
    "general_setting.restriction_ip_address.detail.ip.desc_3": "10.2.30.4",
    "general_setting.restriction_ip_address.detail.ip.desc_4": "10.2.30.0/24",
    "general_setting.restriction_ip_address.detail.ip_duplicated": "The specified IP address already exists",

    // Individual setting
    "individual_setting.page_title": "Individual Setting",
    "individual_setting.language": "Language",
    "individual_setting.language.english": "English",
    "individual_setting.language.japan": "日本語",
    "individual_setting.download_csv.file_format": "Download file format",
    "individual_setting.download_csv.file_format.tooltip": "CSV format with item names as columns",
    "individual_setting.download_csv.file_format.csv": "CSV",
    "individual_setting.download_csv.file_format.csv.tooltip": "CSV format including read reliability and coordinate information",
    "individual_setting.download_csv.file_format.csv_airead": "CSV (AIRead format)",
    "individual_setting.download_csv.file_character_code": "Download file character code",
    "individual_setting.download_csv.file_recognize_newline": "Download file line break format",
    "individual_setting.download_csv.file_recognize_newline.string": "String(\\n)",
    "individual_setting.download_csv.file_recognize_newline.code": "Newline code",
    "individual_setting.download_csv.file_recognize_newline.string.tooltip": "Output as string (\\n)",
    "individual_setting.download_csv.file_recognize_newline.code.tooltip": "Converts a string (\\n) to a newline code and outputs it",
    "individual_setting.read_complete_email_notification": "Get email when reading complete",
    "individual_setting.read_complete_email_notification.on": "On",
    "individual_setting.read_complete_email_notification.off": "Off",
    "individual_setting.save": "Save",

    // Individual learning model management
    "individual_model_mng.page_title": "Individual learning model management",
    "individual_model_mng.no": "No.",
    "individual_model_mng.model_type": "Model type",
    "individual_model_mng.model_name": "Model name",
    "individual_model_mng.model_size": "Size (MB)",
    "individual_model_mng.created_by": "Created by",
    "individual_model_mng.updated_date": "Updated date",
    "individual_model_mng.cancel": "Cancel",
    "definition.remove": "Remove",
    "individual_model_mng.remove": "Remove",
    "individual_model_mng.remove_confirm_msg": "Do you want to remove this model?",
    "individual_model_mng.model_type_print": "Print model",
    "individual_model_mng.model_type_manual": "Manual model",

    // Form setting
    "form_setting.page_title": "Form Setting Management",
    "form_setting.lbl.extract_method": "Extract method",
    "form_setting.lbl.form_setting_name": "Form setting name",
    "form_setting.lbl.basic_setting": "Basic setting",
    "form_setting.lbl.image_processing": "Image processing",
    "form_setting.lbl.rule_specification": "Rule specification",
    "form_setting.lbl.prebuilt.basic_setting": "Basic setting",
    "form_setting.lbl.prebuilt.item_name_setting": "Item name setting",
    "form_setting.lbl.prebuilt_model": "Prebuilt model",
    "form_setting.lbl.coordinate_specification": "Coordinate specification",
    "form_setting.lbl.claude35": "Claude 3.5",
    "form_setting.lbl.claude35_setting_name": "Setting name",
    "form_setting.lbl.claude35.item_name_setting": "Item name setting",
    "form_setting.btn.save": "Save",
    "form_setting.btn.remove": "Remove",
    "form_setting.btn.save_as": "Save as",
    "form_setting.err_requied": "This field can not be empty",
    "form_setting.add_model_title": "Specifying the form setting name",
    "form_setting.update_model_title": "Update confirmation",
    "form_setting.remove_model_title": "Remove confirmation",
    "form_setting.remove_model_content": "Do you want to remove this form setting?",
    "form_setting.not_specified": "Not specified",
    "form_setting.change_setting_without_saving": "Change form setting without saving.\r\nIs it ok?",
    "form_setting.err_out_in_range": "Values must be between {{from}} and {{to}}",
    "form_setting.prebuilt_invoice": "Invoice",
    "form_setting.prebuilt_receipt": "Receipt",
    "form_setting.prebuilt_claude_3_5": "Claude 3.5",
    "form_setting.updated_at": "Updated time",
    "form_setting.prebuilt.item_name_setting.no": "No.",
    "form_setting.prebuilt.item_name_setting.default_item_name": "Default item name",
    "form_setting.prebuilt.item_name_setting.type": "Type",
    "form_setting.prebuilt.item_name_setting.description": "Description",
    "form_setting.prebuilt.item_name_setting.output": "Output",
    "form_setting.prebuilt.item_name_setting.converted_item_name": "Converted item name",
    "form_setting.claude35.item_name_setting.no": "No.",
    "form_setting.claude35.item_name_setting.key": "Item name",
    "form_setting.claude35.item_name_setting.pattern": "Extraction pattern",
    "form_setting.claude35.add_modal_title": "Specifying the setting name",
    "form_setting.claude35.add_modal.setting_name": "Setting name",
    "form_setting.claude35.key_requied": "Item name can not be empty",
    "form_setting.claude35.pattern_requied": "Extraction pattern can not be empty",

    // 01_基本設定タブ/ 01_Basic Settings tab
    "form_setting.ocr_lang": "OCR model for printed text",
    "form_setting.ocr_lang.tooltip": "Model name used in printing text OCR engine.  Specify file name excluding the extension .traineddata.",
    "form_setting.page_seg_mode": "String cut logic",
    "form_setting.page_seg_mode.char_size": "Variable character size",
    "form_setting.page_seg_mode.font_size": "Same font size",
    "form_setting.page_seg_mode.tooltip": "Specifies character recognition algorithms of OCR engine",
    "form_setting.rescan_threshold": "Reload threshold",
    "form_setting.rescan_threshold.tooltip": "Reread if component's Conf values are lower than specified values.",
    "form_setting.rescan_threshold.place_holder": "Please enter as an integer",
    "form_setting.use_dup_char_reducer": "Conduct processing to reduce redundant recognition of characters",
    "form_setting.reduce_target": "Target character type",
    "form_setting.reduce_target.number": "Number",
    "form_setting.reduce_target.symboy": "Symboy",
    "form_setting.reduce_target.hiragana": "Hiragana",
    "form_setting.reduce_target.katakana": "Katakana",
    "form_setting.reduce_target.alphabet": "Alphabet",
    "form_setting.proc_max_page": "Maximum number of pages to be read",
    "form_setting.proc_max_page.tooltip": "Valid only when \"Unit of both output and form sorting\" is \"Output by file units, form sorting on first page only\".",
    "form_setting.file_idenfication_type": "Unit of both output and form sorting",
    "form_setting.file_idenfication_type.0": "Page-by-page output and form sorting",
    "form_setting.file_idenfication_type.1": "Output by file units, form sorting on first page only",

    // 02_座標指定/ 02_Coordinate specification
    "form_setting.handwrite_lang": "OCR model for handwriting",
    "form_setting.use_segment_model": "Use improved handwritten character cut logic",
    "form_setting.export_space": "Include spaces in OCR results",
    "form_setting.hw_replace_thresh": "Conf. % to replace handwrtn characters",
    "form_setting.hw_replace_char": "Character after replacement",
    "form_setting.format_margin": "Recognition margin of alignment figure",
    "form_setting.print_noise_filters": "Printed text noise threshold",
    "form_setting.hw_noise_filters": "Handwritten noise threshold\r\n(Character height. When multiple characters are specified.)",
    "form_setting.hw_white_threshold": "Handwritten noise threshold\r\n(Ratio of white pixels. When one character is specified)",
    // 02_キーワード指定/ 02_Keyword specification
    "form_setting.component_level": "Extraction mode",
    "form_setting.component_level.item": "Keyword mode",
    "form_setting.component_level.cell": "Specify keyword (with table detection)",
    "form_setting.component_level.tooltip": "Set extraction mode.",
    "form_setting.ocr_pdf_with_text": "Conduct OCR on PDF with text",
    "form_setting.use_dl_string_detection": "Use improved string detection logic",
    "form_setting.string_detection_split_large_rectangle": "Improve accuracy of strings with narrow line spacing",
    "form_setting.black_list_ext": "Characters to exclude from OCR results",
    "form_setting.black_list_ext.tooltip": "Enter characters to be excluded from extraction targets.",
    "form_setting.linked_item_threshold": "Space of one text string (N times height)",
    "form_setting.linked_item_threshold.tooltip": "Character space considered as one string. The value is magnification to text height.",
    "form_setting.space_mode": "Use improved space detection logic",
    "form_setting.reduce_image_before_ocr": "Reduce the size of an image if its resolution is too large",
    "form_setting.reduce_image_before_ocr.tooltip": "If the resolution of the long side of the image is larger than 5000 px, reduce it before performing OCR.",

    // 03_画像処理/ 03_ Image processing
    "form_setting.de_noise": "Remove noise",
    "form_setting.is_slope_correction": "Conduct tilt correction",
    "form_setting.auto_rotation": "Rotation correction (every 90 degrees)",
    "form_setting.skip_line_remove": "Disable ruled line removal",
    "form_setting.line_removal_threshold": "Min. pixels for straight line removal",
    "form_setting.line_removal_threshold.tooltip": "Threshold for length considered as straight lines (pixels).  It is set automatically according to text height when 0.",
    "form_setting.line_removal_multiple_by_text_heght": "Multiple of the height of the text when deleting a straight line",
    "form_setting.line_removal_multiple_by_text_heght.tooltip": "If the Min. pixels for straight line removal is \"0\", this parameter sets the threshold for removing a straight line to be the number of times the average character height.",
    "form_setting.hough_threshold": "Min. pixels for dot line removal",
    "form_setting.hough_threshold.tooltip": "Length threshold (pixels) to consider as dotted lines.",
    "form_setting.restore_text": "Restore missing characters damaged by the removal process of ruled lines",
    "form_setting.thresh_value": "Binarization threshold",
    "form_setting.thresh_value.tooltip": "Binarization threshold.  Automatically set from histogram of entire image if it is 0.",
    "form_setting.binthresh_on_line_removal": "Binarization thshold to remove lines",
    "form_setting.binthresh_on_line_removal.tooltip": "The binarization threshold only for removing straight lines. Adjust when straight line removal is not clean. Automatically set from histogram of entire image if it is 0.",
    "form_setting.black_white_threshold": "Black ratio for B/W inversion (%)",
    "form_setting.black_white_threshold.tooltip": "The threshold (%) for reversing black and white after binarization. Invert black and white when the percentage of black is greater than the set percentage.",
    "form_setting.sharpen_value": "Sharpening value",
    "form_setting.sharpen_value.tooltip": "Sharpen images before performing OCR.  Effective when images are blurred. Do not process if 0. If used, the standard value is 0.2 to 0.7.",
    "form_setting.short_line_thresh_h": "Short horizontal line removal thshold",
    "form_setting.short_line_thresh_h.tooltip": "Threshold for detecting and removing short ruled lines (horizontal) intersecting with long ruled lines. Specify length of ruled lines (pixels).",
    "form_setting.short_line_thresh_v": "Short vertical line removal thshold",
    "form_setting.short_line_thresh_v.tooltip": "Threshold for detecting and removing short ruled lines (vertical) intersecting with long ruled lines.  Specify length of ruled lines (pixels).",
    "form_setting.use_short_dotline_removal": "Also removes dotted lines (processing time will be increased by appx 2 seconds per A4 sized image)",
    "form_setting.thin_line_removal_threshold_w": "Fine noise removal thshold (width)",
    "form_setting.thin_line_removal_threshold_w.tooltip": "Removes noise finer than specified widths (pixels).",
    "form_setting.thin_line_removal_threshold_h": "Fine noise removal thshold (height)",
    "form_setting.thin_line_removal_threshold_h.tooltip": "Removes noise finer than specified heights (pixels).",
    "form_setting.erode_thin_line_w": "Shrink filter(width) for fine noises",
    "form_setting.erode_thin_line_w.tooltip": "Filter size (width) for fine noise removal erode processing.  Specify an integer of 1 or greater.",
    "form_setting.erode_thin_line_h": "Shrink filter(height) for fine noises",
    "form_setting.erode_thin_line_h.tooltip": "Filter size (height) for fine noise removal erode processing.  Specify an integer of 1 or greater.",
    "form_setting.conv_tiff_dpi": "Convert TIFF to 300DPI before executing OCR",
    "form_setting.extraction_colors": "Color to select",
    "form_setting.extraction_colors.tooltip": "Specify colors to be extracted.",
    "form_setting.removal_colors": "Color to remove",
    "form_setting.removal_colors.tooltip": "Specifies colors to be removed.",
    "form_setting.colors.black": "Black",
    "form_setting.colors.red": "Red",
    "form_setting.colors.yellow": "Yellow",
    "form_setting.colors.green": "Green",
    "form_setting.colors.water": "Water color",
    "form_setting.colors.blue": "Blue",
    "form_setting.colors.purple": "Purple",
    "form_setting.colors.custom": "Custom",
    "form_setting.filter_color_before_lineremoval": "Conduct color selection/removal before removing ruled lines",
    "form_setting.line_extension_len": "Length to extend ruled line",
    "form_setting.line_extension_len.tooltip": "Length (pixels) to extend both ends of ruled line. Specify 0 or greater.",
    "form_setting.rounded_corner_threshold": "Thshold to remove rounded corner",
    "form_setting.rounded_corner_threshold.tooltip": "Removes rounded corners whose radius is less than the specified pixel value.  Will not work if 0 is specified.",
    "form_setting.rounded_corner_padding": "Length of rounded corner removal",
    "form_setting.rounded_corner_padding.tooltip": "Extends the removal range by the number of pixels specified when removing rounded corners. Increasing this value will reduce the amount of unerased characters, but characters may be erased accordingly.",
    "form_setting.remove_dotted_background": "Remove background dots",
    "form_setting.dot_size_threshold": "Dot size",
    "form_setting.dot_size_threshold.tooltip": "Remove dots smaller than specified size.",
    "form_setting.remove_small_rectangles": "Remove small rectangles",
    "form_setting.remove_small_rectangles.tooltip": "If the detected rectangular area is [minimum area <rectangular area <maximum area], it is regarded as a small rectangle and removed.",
    "form_setting.remove_rectangle_min_area_threshold": "Minimum area of ​​small rectangle to remove",
    "form_setting.remove_rectangle_max_area_threshold": "Maximum area of ​​small rectangle to remove",
    "form_setting.remove_diagonal_lines": "Remove diagonal lines",
    "form_setting.remove_diagonal_lines.tooltip": "Remove the diagonal lines in a table.",

    "form_setting.specifying_form_settings.page_title": "Specifying form settings",
    "form_setting.specifying_form_settings.form_name": "Form setting name",
    "form_setting.specifying_form_settings.update_confirm": "Update confirmation",
    "form_setting.specifying_form_settings.change_information": "Do you want to change information?",
    "form_setting.specifying_form_settings.save": "Save",
    "form_setting.specifying_form_settings.cancel": "Cancel",

    // 事前構築済みモデル 基本設定
    "form_setting.prebuilt.enable_empty_output": "Empty output for items that could not be retrieved",

    // 事前構築済みモデル 項目名設定
    "form_setting.prebuilt.type.header": "Header",
    "form_setting.prebuilt.type.items": "Items",
    "form_setting.prebuilt.type.tax_details": "TaxDetails",
    "form_setting.prebuilt.invoice.customer_name": "Invoiced customer",
    "form_setting.prebuilt.invoice.customer_id": "Customer reference ID",
    "form_setting.prebuilt.invoice.purchase_order": "Purchase order reference number",
    "form_setting.prebuilt.invoice.invoice_id": "ID for this specific invoice",
    "form_setting.prebuilt.invoice.invoice_date": "Date the invoice was issued",
    "form_setting.prebuilt.invoice.due_date": "Date payment for this invoice is due",
    "form_setting.prebuilt.invoice.vendor_name": "Vendor name",
    "form_setting.prebuilt.invoice.vendor_tax_id": "The taxpayer number associated with the vendor",
    "form_setting.prebuilt.invoice.vendor_address": "Vendor mailing address",
    "form_setting.prebuilt.invoice.vendor_address_recipient": "Name associated with the VendorAddress",
    "form_setting.prebuilt.invoice.customer_address": "Mailing address for the Customer",
    "form_setting.prebuilt.invoice.customer_tax_id": "The taxpayer number associated with the customer",
    "form_setting.prebuilt.invoice.customer_address_recipient": "Name associated with the CustomerAddress",
    "form_setting.prebuilt.invoice.billing_address": "Explicit billing address for the customer",
    "form_setting.prebuilt.invoice.billing_address_recipient": "Name associated with the BillingAddress",
    "form_setting.prebuilt.invoice.shipping_address": "Explicit shipping address for the customer",
    "form_setting.prebuilt.invoice.shipping_address_recipient": "Name associated with the ShippingAddress",
    "form_setting.prebuilt.invoice.payment_term": "The terms of payment for the invoice",
    "form_setting.prebuilt.invoice.sub_total": "Subtotal field identified on this invoice",
    "form_setting.prebuilt.invoice.total_tax": "Total tax field identified on this invoice",
    "form_setting.prebuilt.invoice.invoice_total": "Total new charges associated with this invoice",
    "form_setting.prebuilt.invoice.amount_due": "Total Amount Due to the vendor",
    "form_setting.prebuilt.invoice.service_address": "Explicit service address or property address for the customer",
    "form_setting.prebuilt.invoice.service_address_recipient": "Name associated with the ServiceAddress",
    "form_setting.prebuilt.invoice.remittance_address": "Explicit remittance or payment address for the customer",
    "form_setting.prebuilt.invoice.remittance_address_recipient": "Name associated with the RemittanceAddress",
    "form_setting.prebuilt.invoice.service_start_date": "First date for the service period",
    "form_setting.prebuilt.invoice.service_end_date": "End date for the service period",
    "form_setting.prebuilt.invoice.previous_unpaid_balance": "Explicit previously unpaid balance",
    "form_setting.prebuilt.invoice.currency_code": "The currency code associated with the extracted amount",
    "form_setting.prebuilt.invoice.payment_details": "An array that holds Payment Option details such as IBAN,SWIFT, BPay(AU)",
    "form_setting.prebuilt.invoice.total_discount": "The total discount applied to an invoice",
    "form_setting.prebuilt.invoice.items": "Full string text line of the line item",
    "form_setting.prebuilt.invoice.amount": "The amount of the line item",
    "form_setting.prebuilt.invoice.description": "The text description for the invoice line item",
    "form_setting.prebuilt.invoice.quantity": "The quantity for this invoice line item",
    "form_setting.prebuilt.invoice.unit_price": "The net or gross price of one unit of this item",
    "form_setting.prebuilt.invoice.product_code": "Product code, product number, or SKU associated with the specific line item",
    "form_setting.prebuilt.invoice.unit": "The unit of the line item, e.g, kg, lb etc.",
    "form_setting.prebuilt.invoice.date": "Date corresponding to each line item. Often it's a date the line item was shipped",
    "form_setting.prebuilt.invoice.tax": "Tax associated with each line item. Possible values include tax amount and tax Y/N",
    "form_setting.prebuilt.invoice.tax_rate": "Tax Rate associated with each line item",
    "form_setting.prebuilt.receipt.merchant_name": "Name of the merchant issuing the receipt",
    "form_setting.prebuilt.receipt.merchant_phone_number": "Listed phone number of merchant",
    "form_setting.prebuilt.receipt.merchant_address": "Listed address of merchant",
    "form_setting.prebuilt.receipt.total": "Full transaction total of receipt",
    "form_setting.prebuilt.receipt.transaction_date": "Date the receipt was issued",
    "form_setting.prebuilt.receipt.transaction_time": "Time the receipt was issued",
    "form_setting.prebuilt.receipt.subtotal": "Subtotal of receipt, often before taxes are applied",
    "form_setting.prebuilt.receipt.total_tax": "Tax on receipt, often sales tax, or equivalent",
    "form_setting.prebuilt.receipt.tip": "Tip included by buyer",
    "form_setting.prebuilt.receipt.arrival_date": "Date of arrival",
    "form_setting.prebuilt.receipt.departure_date": "Date of departure",
    "form_setting.prebuilt.receipt.currency": "Currency unit of receipt amounts (ISO 4217), or 'MIXED' if multiple values are found",
    "form_setting.prebuilt.receipt.merchant_aliases": "Alternative name of merchant",
    "form_setting.prebuilt.receipt.total_price": "Total price of line item",
    "form_setting.prebuilt.receipt.description": "Item description",
    "form_setting.prebuilt.receipt.quantity": "Quantity of each item",
    "form_setting.prebuilt.receipt.price": "Individual price of each item unit",
    "form_setting.prebuilt.receipt.product_code": "Product code, product number, or SKU associated with the specific line item",
    "form_setting.prebuilt.receipt.quantity_unit": "Quantity unit of each item",
    "form_setting.prebuilt.receipt.date": "Item date",
    "form_setting.prebuilt.receipt.category": "Item category",
    "form_setting.prebuilt.receipt.amount": "	The amount of the tax detail",

    "team.page_title": "Team Management",
    "team.page_edit_team_title": "Edit team",
    "team.no": "No.",
    "team.team_id": "Team ID",
    "team.contractor": "Contractor",
    "team.contractor_name": "Arise Innovation Co., Ltd. Product Development Team 2",
    "team.create_new_team": "Create a new team",
    "team.team_name": "Team name",
    "team.created_date": "Created date",
    "team.updated_date": "Updated date",
    "team.remove": "Remove",
    "team.remove_team": "Remove team",
    "team.change": "Change",
    "team.add_user": "Add user",
    "team.user_id": "User ID",
    "team.user_name": "User name",
    "team.user_level": "User level",
    "team.modal_team_management_title": "Team management [Management]",
    "team.modal_add_user_title": "Team Edit [Add User]",
    "team.filter_condition": "Filtering conditions",
    "team.save": "Save",
    "team.update": "Update",
    "team.remove_confirm_msg": "Are you sure you want to delete this team?\r\nThe deleted team files will no longer be displayed\r\n on the CSV download screen.",
    "team.remove_user_confirm_msg": "Do you want to remove this item?",
    "team.add_user_confirm_msg": "From the team edit screen to the created team,\r\nplease add a user.",

    "post_processing.page_title": "Post-processing setting management (Result conversion)",
    "post_processing.definition_name": "Definition name",
    "post_processing.created_by": "Created by",
    "post_processing.remove_post_processing_confirm_msg": "Do you want to remove this result conversion setting?",

    "template_sorting.common.setting_name": "Definition name",
    "template_sorting.common.classification_type": "Classification type",
    "template_sorting.common.classification_type.image": "Image sorting",
    "template_sorting.common.classification_type.keyword": "Keyword sorting",
    "template_sorting.common.max_features": "Number of feature points extracted",
    "template_sorting.common.match_ct_th": "Minimum number of feature points",
    "template_sorting.common.size_diff_th": "Image comparison skip threshold",
    "template_sorting.common.keyword_rect": "Range you want to read by sorting",
    "template_sorting.mng.page_title": "Sorting Management",
    "template_sorting.mng.id": "Definition ID",
    "template_sorting.mng.create": "Create new definition",
    "template_sorting.mng.remove_confirm_msg": "Do you want to remove this definition?",
    "template_sorting.mng.modal.dialog_title": "Sorting Management[Management]",
    "template_sorting.mng.modal.max_features.tooltip": "The number to detect characteristic points used to compare images. You can specify an integer value of 1 or more.",
    "template_sorting.mng.modal.match_ct_th.tooltip": "The minimum number of feature point matches required to determine that the input image and the template image are similar. You can specify an integer value of 0 or more.",
    "template_sorting.mng.modal.size_diff_th.tooltip": "Compare the pixel areas of the template image and the input image, and skip the comparison with the template image if the difference is greater than this value. If -1 is specified, skip processing is not performed. You can specify an integer value greater than or equal to -1.",
    "template_sorting.mng.modal.keyword_rect.tooltip": "Specify by direction (T: top, B: bottom, R: right, L: left) and percentage.\r\nExample) T,20,L,50 -> 20% from the top and 50% from the left\r\nBy narrowing down the scope, processing time can be reduced.",
    "template_sorting.mng.modal.add_form_confirm_msg": "Edit the created sorting definition from the sorting edit screen.",
    "template_sorting.edit.page_title": "Edit Sorting",
    "template_sorting.edit.change": "Change",
    "template_sorting.edit.formGroup": "[Group]",
    "template_sorting.edit.formGroup_default": "[Please select]",
    "template_sorting.edit.edition": "Edition",
    "template_sorting.edit.edition.manual": "Coordinate",
    "template_sorting.edit.edition.rule": "Rule v2",
    "template_sorting.edit.form_group_name": "Group Name",
    "template_sorting.edit.form_id": "Form ID",
    "template_sorting.edit.config_name": "Config Name",
    "template_sorting.edit.status": "Images",
    "template_sorting.edit.regist": "Image registration",
    "template_sorting.edit.keyword": "Keyword",
    "template_sorting.edit.keyword.tooltip": "Search and identify keywords using an AND condition when separated by a half-width space, or an OR condition when separated by a semicolon (;)",
    "template_sorting.edit.status_0": "Unregistered",
    "template_sorting.edit.status_1": "Registered",
    "template_sorting.edit.regist_btn": "Registration",
    "template_sorting.edit.back": "Back",
    "template_sorting.edit.save": "Save",
    "template_sorting.edit.modal.change_group": "Update confirmation",
    "template_sorting.edit.modal.change_information": "The template will be deallocated.\r\nIs it OK?",
    "template_sorting.regist.page_title": "Image registration",
    "template_sorting.upload.file_size_over_input": "File size should be within 10.0 MB. (JPG, TIFF, PNG and up to 5 files).",
    "template_sorting.upload.file_size_over": "File size should be within 100.0 MB. (JPG, TIFF, PNG)",
    "template_sorting.upload.file_type_not_allow": "The format of the uploaded file is invalid",
    "template_sorting.upload.cant_upload_warning": "Warning",
    "template_sorting.upload.cant_upload_5": "Up to 5 files can be registered",
    "template_sorting.upload.exists_same_file_name": "A file with the same name exists",
    "template_sorting.upload.close": "Close",
    "template_sorting.upload.no": "No.",
    "template_sorting.upload.filename": "Name",
    "template_sorting.upload.format": "Format",
    "template_sorting.upload.size": "Size",
    "template_sorting.upload.regist_status": "Registration",
    "template_sorting.upload.status": "Status",
    "template_sorting.upload.action": "Unassign",
    "template_sorting.upload.back": "Back",
    "template_sorting.upload.regist": "Registration",
    "template_sorting.upload.cancel": "Cancel",
    "template_sorting.upload.confirmation_delete": "Confirmation of unassignment",
    "template_sorting.upload.confirm_delete_desc": "Do you want to unassign the template image?",
    "template_sorting.upload.remove": "Unassign",
    "template_sorting.upload.confirmation": "Confirmation",
    "template_sorting.upload.confirm_desc": "Register the images. \ r \ n Are you sure?",
    "template_sorting.upload.regist": "Registration",
    "template_sorting.regist.imagetype_1": "JPG",
    "template_sorting.regist.imagetype_2": "PNG",
    "template_sorting.regist.imagetype_3": "TIFF",
    "template_sorting.regist.status_0": "Unregistered",
    "template_sorting.regist.status_1": "Registered",

    // For log
    "log.title": "Logs",
    "log.download": "Download CSV",
    "log.no": "No.",
    "log.action": "Action",
    "log.action_detail": "Action detail",
    "log.user_id": "User ID",
    "log.executed_at": "Executed at",
    "log.action_record_upload": "Report Upload",
    "log.action_csv_download": "CSV Download",
    "log.action_form_definition_upload": "Form Definition Upload",
    "log.action_correct_reading_result": "Correct Reading Result",
    "log.action_login": "Login",
    "log.action_logout": "Logout",
    "log.action_delete_document": "Delete Document",
    "log.action_debug_download": "Download Debug Info",
    "log.action_record_download": "Report Download",
    "log.btn_update": "Update",

    "log_search_box.narrowing_conditions": "Narrowing Conditions",
    "log_search_box.executed_date": "[Executed date]",
    "log_search_box.to_date": "[To Date]",
    "log_search_box.user_id": "[User ID]",
    "log_search_box.all_definition_file": "[Please select]",
    "log_search_box.action": "[Action]",
    "log_search_box.action_login": "Login",
    "log_search_box.action_logout": "Logout",
    "log_search_box.action_record_upload": "Report Upload",
    "log_search_box.action_csv_download": "CSV Download",
    "log_search_box.action_form_definition_upload": "Form Definition Upload",
    "log_search_box.action_correct_reading_result": "Correct Reading Result",
    "log_search_box.action_delete_document": "Delete Document",
    "log_search_box.action_debug_download": "Download Debug Info",
    "log_search_box.action_record_download": "Report Download",

    // For documents download
    "documents_download.no": "No.",
    "documents_download.title": "Report download",
    "documents_download.file_name": "File name",
    "documents_download.definition_file": "Definition file",
    "documents_download.registered_date": "Registered date",
    "documents_download.team_name": "Team name",
    "documents_download.user_id": "Creator",
    "documents_download.status": "Status",
    "documents_download.download": "Download",
    "documents_download.form_group": "Group",
    "documents_download.select_to_download": "Please select the data to download.",
    'documents_download.brand_name': "AIRead on Cloud",
    "documents_download.status_0": "Not Downloaded",
    "documents_download.status_1": "Downloaded",
    "documents_download.btn_update": "Update",
    "documents_download.selected_item": "Selected item(s): ",
    "documents_download.prebuilt": "Prebuilt-Model",
    "documents_download.prebuilt_invoice": "Invoice",
    "documents_download.prebuilt_receipt": "Receipt",
    "documents_download.prebuilt_claude_3_5": "Claude 3.5",

    // For download search box
    "download_search_box.file_name": "[File name]",
    "download_search_box.file_name_place_holder": "File name",
    "download_search_box.formGroup": "[Group]",
    "download_search_box.definition_file": "[Definition file]",
    "download_search_box.narrowing_conditions": "Narrowing Conditions",
    "download_search_box.registered_date": "[Registered date]",
    "download_search_box.to_date": "[To Date]",
    "download_search_box.status": "[Status]",
    "download_search_box.not_downloaded": "Not Downloaded",
    "download_search_box.downloaded": "Downloaded",
    "download_search_box.search": "Search",
    "download_search_box.ok": "OK",
    "download_search_box.brand_name": "AIRead on Cloud",
    "download_search_box.from_time_greater_to_time": "To Date should be greater than From Date",
    "download_search_box.all_definition_file": "[Please select]",
    "download_search_box.team_name": "[Team name]",
    "download_search_box.user_id": "[Creator]"
  }
}